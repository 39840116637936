/* External dependencies */
import { Div, Text } from 'atomize';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import { RootState } from '@app/redux/store';
import Search from '@shared/ui/search/Search';
import Tabs from '@shared/ui/tab/Tabs';
import { EntryStatus, EntryType } from '../../../entities/users/lib/types';
import { userFilters } from '../../../entities/users/lib/user-details-helper';
import { listUsers } from '../../../entities/users/redux/list-users-redux/actions';

export default function MeducationManagerControlUser() {
  const dispatch = useDispatch();
  const { filter, currentPage, searchString, total, loading } = useSelector(
    (state: RootState) => state.users,
  );
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      listUsers(
        '',
        {
          ...filter,
          entryTypes: EntryType.PHARMACIST,
          status: EntryStatus.INCOMPLETE,
        },
        currentPage,
      ),
    );
  }, []);

  const searchTextManagerPlaceholder = t(
    'searchTextMeducationManagerPlaceholder',
  );

  function onSearch(e) {
    dispatch(listUsers(e.target.value, filter, currentPage));
  }

  function resetSearchField() {
    onSearch({
      target: { value: '' },
    });
  }

  function handleSelectFilter(value) {
    dispatch(
      listUsers(
        searchString,
        {
          entryTypes: filter.entryTypes,
          status: value,
        },
        1,
      ),
    );
  }

  return (
    <>
      <Text textSize="subtitle" textWeight="700" m={{ b: '20px' }}>
        {searchString ? (
          <Trans>resultOfSearch</Trans>
        ) : (
          <Trans>pharmacists</Trans>
        )}{' '}
        ({loading ? '...' : total})
      </Text>
      <Div m={{ b: '20px' }} d="flex" style={{ gap: '30px' }}>
        <Div>
          <Search
            onChange={onSearch}
            placeholder={searchTextManagerPlaceholder}
            onClear={resetSearchField}
            value={searchString}
          />
        </Div>
        <Div align="baseline" d="flex" justify="flex-start">
          <Div m={{ b: { xs: '1rem', md: '0' } }}>
            <Tabs
              inputName="filterType"
              value={filter?.status}
              items={userFilters}
              onClick={handleSelectFilter}
            />
          </Div>
        </Div>
      </Div>
    </>
  );
}

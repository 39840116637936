// External dependensies
import { Div, Text } from 'atomize';
import { navigate } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependensies
import { RootState } from '@app//redux/store';
import ManagerControlUser from '@feautures/ui/users-controller/ManagerControlUser';
import MeducationManagerControlUser from '@feautures/ui/users-controller/MeducationManagerBar';
import Header from '@shared/ui/admin/header/Header';
import ErrorPopup from '@shared/ui/popup/ErrorPopup';
import Table, { RowSelectionType } from '@shared/ui/table/Table';
import { setLocalStorage } from '@shared/utils/helpers';
import { getDateTime } from '@shared/utils/renderDate';
import { UserRoles, getUserRole } from '@shared/utils/roles';
import DefaultAvatarIcon from '@svg/DefaultAvatarIcon';
import getUserFields from '../lib/helper';
import {
  listUsers,
  listUsersErrorReset,
} from '../redux/list-users-redux/actions';

export default function ListUsers() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.login);
  const { loading, users, filter, currentPage, total, searchString, error } =
    useSelector((state: RootState) => state.users);
  const { t } = useI18next();

  const errorMessage = error?.code ? (
    <Trans>{error?.code}</Trans>
  ) : (
    error?.message
  );

  function getImage(user) {
    return (
      <>
        {user?.avatar ? (
          <img
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
            src={user?.avatar}
            alt={user?.firstName}
          />
        ) : (
          <DefaultAvatarIcon />
        )}
      </>
    );
  }

  function getUserAndNavigateToDetails(id: string, __typeName: string) {
    setLocalStorage('userId', id);
    setLocalStorage('userTypename', __typeName);

    navigate('user-details');
  }

  function onPageChange(page) {
    dispatch(listUsers(searchString, filter, page));
  }

  function resetError() {
    dispatch(listUsersErrorReset());
  }

  return (
    <Div m="0 auto" className="container">
      <Header />
      <Div p={{ t: '80px' }}>
        <Table
          items={users?.map((user) => ({
            id: user?.id,
            photo: getImage(user),
            firstName: user?.firstName
              ? `${user?.firstName} ${user?.lastName}`
              : ' ',
            clinicName: user?.clinicName || ' ',
            joinDate: getDateTime(user?.joinDate),
            status: user?.status,
            typeName: t(user?.typename),
            __typeName: user?.typename,
          }))}
          onClickItem={getUserAndNavigateToDetails}
          columns={getUserFields()}
          currentPage={currentPage}
          emptyMessage="noUsers"
          controlBar={getControlBar()}
          loading={loading}
          onPageChange={onPageChange}
          stickyCol={1}
          searchString={''}
          selectionType={RowSelectionType.NUMBER}
          topBar={''}
          totalItemsCount={total}
        />
      </Div>
      {error && (
        <ErrorPopup
          onSubmit={resetError}
          submitText="close"
          title="errorOccurred"
        >
          <Text data-testid="error-text">{errorMessage}</Text>
        </ErrorPopup>
      )}
    </Div>
  );

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (true) {
      case userRole.includes(UserRoles.MANAGER): {
        return <ManagerControlUser />;
      }

      case userRole.includes(UserRoles.MEDUCATION_MANAGER): {
        return <MeducationManagerControlUser />;
      }

      default:
        return null;
    }
  }
}

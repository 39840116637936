/* External dependencies */
import { Div, Text, Icon } from 'atomize';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import { RootState } from '@app/redux/store';
import {
  EntryStatus,
  EntryType,
  filterEntryType,
} from '@entities/users/lib/types';
import { userFilters } from '@entities/users/lib/user-details-helper';
import { listUsers } from '@entities/users/redux/list-users-redux/actions';
import CustomDropdown from '@shared/ui/custom-dropdown';
import Search from '@shared/ui/search/Search';
import Tabs from '@shared/ui/tab/Tabs';
import Button from '@shared/ui/button';

export default function ManagerControlUser() {
  const dispatch = useDispatch();
  const { filter, currentPage, searchString, total, loading } = useSelector(
    (state: RootState) => state.users,
  );
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      listUsers(
        '',
        {
          entryTypes: [
            EntryType.PHARMACIST,
            EntryType.CLINIC,
            EntryType.PHYSICIAN,
          ],
          status: EntryStatus.INCOMPLETE,
        },
        currentPage,
      ),
    );
  }, []);

  const searchTextManagerPlaceholder = t('searchTextManagerPlaceholder');

  function retryListUsers() {
    dispatch(listUsers(searchString, filter, currentPage));
  }

  function onSearch(e) {
    dispatch(listUsers(e.target.value, filter, currentPage));
  }

  function resetSearchField() {
    onSearch({
      target: { value: '' },
    });
  }

  function handleSelectFilter(value) {
    dispatch(
      listUsers(
        searchString,
        {
          entryTypes: filter.entryTypes,
          status: value,
        },
        1,
      ),
    );
  }

  function handleSelectTypeFilter(value) {
    if (value === 'ALL') {
      value = [EntryType.PHARMACIST, EntryType.CLINIC, EntryType.PHYSICIAN];
    } else {
      value = [value];
    }

    dispatch(
      listUsers(
        searchString,
        {
          entryTypes: value,
          status: filter.status,
        },
        1,
      ),
    );
  }

  return (
    <>
      <Div
        d="flex"
        style={{ gap: '1.5rem' }}
        flexDir={{ xs: 'column', sm: 'row' }}
        m={{ b: '20px' }}
      >
        <Text textSize="subtitle" textWeight="700">
          {searchString ? <Trans>resultOfSearch</Trans> : <Trans>users</Trans>}{' '}
          ({loading ? '...' : total})
        </Text>
        <Button
          typeColor="primary"
          onClick={retryListUsers}
          dataTestid="users-refresh"
          width="100px"
        >
          <Icon color="white" name="Refresh" size="25px" />
        </Button>
      </Div>
      <Div m={{ b: '20px' }} d="flex" style={{ gap: '30px' }}>
        <Div>
          <Search
            onChange={onSearch}
            placeholder={searchTextManagerPlaceholder}
            onClear={resetSearchField}
            value={searchString}
          />
        </Div>
        <Div align="baseline" d="flex" justify="flex-start">
          <Div m={{ b: { xs: '1rem', md: '0' } }}>
            <Tabs
              inputName="filterType"
              value={filter?.status}
              items={userFilters}
              onClick={handleSelectFilter}
            />
          </Div>
        </Div>
        <Div align="baseline" d="flex" justify="flex-start">
          <CustomDropdown
            disabled={false}
            items={filterEntryType}
            name={
              filter?.entryTypes?.length > 1 ? 'ALL' : filter?.entryTypes[0]
            }
            onSelectAction={handleSelectTypeFilter}
          />
        </Div>
      </Div>
    </>
  );
}

// External dependensies
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

// Local dependensies
import { RootState } from '@app//redux/store';
import { updateOneSignalExternalUserId } from '@entities/notification/connectOneSignal';
import ListUsers from '@entities/users/ui/ListUsers';
import UserLoggedIn from '@feautures/ui/userLoggedIn/UserLoggedIn';
import Layout from '@shared/ui/layout/WebLayout';
import { pagePermissions } from '../../../PagePermissions';

export default function ProAccounts({ data }) {
  const { currentUser } = useSelector((state: RootState) => state.login);

  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = data;
  const { t } = useI18next();

  useEffect(() => {
    updateOneSignalExternalUserId(currentUser?.sub);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', t('adminPanelForUsers'))}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <UserLoggedIn roles={pagePermissions.listUsers}>
        <ListUsers />
      </UserLoggedIn>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;

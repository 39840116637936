// External dependecies
import React from 'react';
import { Trans } from 'react-i18next';

export default function getUserFields() {
  return [
    {
      field: 'photo',
      title: <Trans>Photo</Trans>,
    },
    {
      field: 'firstName',
      title: <Trans>firstName</Trans>,
    },
    {
      field: 'clinicName',
      title: <Trans>ClinicName</Trans>,
    },

    {
      field: 'typeName',
      title: <Trans>typename</Trans>,
    },
    {
      field: 'joinDate',
      title: <Trans>joinDate</Trans>,
    },
    {
      field: 'status',
      title: <Trans>status</Trans>,
    },
  ];
}

export enum ActionsUsersMenuList {
  DEACTIVATE = 'DEACTIVATE',
  ACTIVATE = 'ACTIVATE',
  EDIT = 'EDIT',
}

export const usersFilters = [
  {
    label: 'ENABLED',
    value: JSON.stringify('ENABLED'),
  },
  {
    label: 'DISABLED',
    value: JSON.stringify('DISABLED'),
  },
];
